body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
}

img {
  max-height: initial !important;
  max-width: initial !important;
}

.pageheader {
  position: relative;
  height: 20mm;
}

.pageheader .beschreibung_pos {
  position: absolute;
  top: 0;
  width: 50%;
}

.pageheader .logo {
  position: relative;
  text-align: center;
}

.pageheader .produkt {
  font-size: 20pt;
  padding-top: 1mm;
  padding-right: 2mm;
}

.pageheader .beschreibung {
  font-size: 11pt;
  padding-top: 2mm;
  padding-right: 2mm;
}

table.bom {
  width: 185mm;
  margin-right: 15mm;
  margin-left: 10mm;
  border: 1px solid black;
  font-size: 7pt;
}

.bom thead {
  border: 1px solid black;
}

.bom thead th {
  border: 1px solid black;
  color: black;
  vertical-align: middle !important;
}

.bom tbody {
  border: 1px solid black;
}

.bom tr {
  border: 1px solid black;
}

.bom td {
  border: 1px solid black;
  vertical-align: middle;
}

.print-format td,
.print-format thead td {
  padding: 0mm !important;
}

.print-format td {
  padding: 2mm !important;
}

.print-format thead {
  padding: 0mm !important;
}